import React, {Component} from 'react';
import Header from '../Header/Header';
import SideBar from '../SideBar/SideBar';
import {Link} from "react-router-dom";
import { Dots, Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import axios from 'axios';
import moment from 'moment';
import CustomSelect from '../CustomSelect/CustomSelect';
import CurrencyFormat from 'react-currency-format';
import './Transactions.css';
import {API_URL, config} from '../../config/baseUrl';

const options = [
    { value: 'transfer', label: 'Transfer' },
    { value: 'bill', label: 'Bill' },
    { value: 'fx', label: 'Fx' },
    { value: 'card', label: 'Card' }
  ]

export default class Transactions extends Component {

    state = {
        loading: true,
        transactionsList: [],
        awaitingTransactionsCount: 0,
        allTransactionList: [],
        awaitingCount: 0,
        queryString: '',
        transactionsLoading: false,
        showIncoming: false,
        showAllTransactions: false,
        showAwaitingTransactions: false,
        isOpen: false,
        selectedData: {value: 'all', label: "All Transactions"},
        skip: 0,
        limit: 20
     }

     componentWillMount(){
        this.getAllTransactions();
    
    }
    

    getTransactionsAPI = async (status) => {
        try {
            this.setState({loading: true})
            const query = status == "all" ? '' : 'status'
            const response = await axios.get(API_URL + `/user/transactions/filter/0/200?${query}=${status}`, config);
            if(response.data.message == "success") {
              return {transactions: response.data.transactions, awaitingCount: response.data.awaitingCount};
            }
        } catch (error) {
            console.log("Transaction gets", {error})
        }
    }

    getAllTransactions = async () => {
        try {
            const response = await this.getTransactionsAPI("completed")
            if(response) {
                this.setState({
                    transactionsList: response.transactions, 
                    awaitingCount: response.awaitingCount,
                    loading:false
                })
            }
        } catch (error) {
            console.log("Transaction gets", {error})
        }
    }

    showAllTransactionsFunc = async () => {
        try {
            const response = await this.getTransactionsAPI("all")
            if(response) {
                this.setState({
                    transactionsList: response.transactions, 
                    loading:false
                })
            }
        } catch (error) {
            console.log("Transaction gets", {error})
        }
    }

    showAwaitingTransactionsFunc = async () => {
        try {
            const response = await this.getTransactionsAPI("awaiting")
            if(response) {
                this.setState({
                    transactionsList: response.transactions,
                    loading:false
                })
            }
        } catch (error) {
            console.log("Transaction gets", {error})
        }
    }

    filterTransactionType = async () => {
        try {
            const response = await this.getTransactionsAPI("awaiting")
            if(response) {
                this.setState({
                    transactionsList: response.transactions,
                    loading:false
                })
            }
        } catch (error) {
            console.log("Transaction gets", {error})
        }
    }


    handleChange = (event) => {
        this.setState({queryString: event.target.value});
        if (event.target.value == '') {
            this.setState({transactionsList: this.state.allTransactionList});
        }
    }

    findUserTransactions = async () => {
        this.setState({transactionsLoading: true})
        const {queryString} = this.state;
        try {
            const response = await axios.get(API_URL + `/user/transactions/find/0/50?${"queryString"}=${queryString}`, config);
            if(response.data.message == "success") {
               // console.log("find transactions", response.data)
                this.setState({transactionsList: response.data.transactions, transactionsLoading: false, showIncoming: true})
            }else {
                this.setState({transactionsLoading: false})
            }
        } catch (error) {
            console.log('error ' + error);
        }
       
    }

    searchUserList = async () => {
        const {queryString} = this.state;
         if (queryString != "") {
             const filteredList = []
             this.state.allTransactionList.map(transaction => {
                if (queryString == transaction.TRANS_ID || 
                    queryString == transaction._userID || 
                    queryString == transaction.accountNumber
                    ) {
                    filteredList.push(transaction)
                }
            })
           this.setState({transactionsList: filteredList})
         }
  
     }

     getTransactionsCategory = async () => {
        try {
            this.setState({loading: true})
           // const query = status == "all" ? '' : 'status'
           console.log("this.state.selectedData", this.state.selectedData)
            const response = await axios.get(API_URL + `/user/transactions/filter/0/100?category=${this.state.selectedData.value}`, config);
            if(response.data.message == "success")  this.setState({
                transactionsList: response.data.transactions,
                loading:false
            })
              
        } catch (error) {
            console.log("Transaction gets", {error})
        }
    }

isInComingTransfer = (type) => {
    if(type == "CREDIT")  return true;
    else return false;
}

showIncomingFunc = (trans) => {
    if(trans.category == "transfer" &&  trans.entry == "CREDIT") return false;
    else return true;
}

isOpenFunc = () => {
    this.setState({isOpen: !this.state.isOpen})
}

onSelectFunc = (item) => {
    this.setState({selectedData: item, isOpen: false}, () => {
        this.getTransactionsCategory();
    })
}



 render() {

        const transactionsList = this.state.transactionsList.map(transaction => {

            return (
                    this.state.loading ? 
                    <div style={{position: 'relative', marginLeft: '55%', marginTop: '15%'}}>
                        <Spinner color="#47c494" size={30}/>   
                    </div>          
                    :

                    <ul key={transaction._id} className="list-contents" 
                       style={{backgroundColor: this.isInComingTransfer(transaction.category) ? '#fcf5fb' 
                           : transaction.isBold ? '#F4F8FE' : '#FFFFFF',
                         }}>
                        <li className="list-items">
                            {transaction.category == "transfer" &&
                              <img className="list-items-icon" 
                              src={this.isInComingTransfer(transaction.entry) ? "/down-icon-dodo.png" 
                              : transaction.receiverMetaData ? "bank-transfer-icon.png" : "/down-icon.png"} />
                             }
                             {transaction.category == "reward" || transaction.category == "cashback" && 
                              <img className="list-items-icon" src="/up-icon2.png" />
                             }
                             {transaction.category == "card" && 
                              <img style={{opacity:0.7}} className="list-items-icon" src="/card1.png" />
                             }
                             {transaction.category == "fx" && 
                              <img style={{opacity:0.7}} className="list-items-icon" src="/fx1.png" />
                             }
                             {transaction.category == "funding" && 
                              <img className="list-items-icon" src="/add-funds.png" />
                             }
                             {transaction.category == "bill" && 
                              <img className="list-items-icon" src="/bills-payment2.png" />
                             }
                             {transaction.category == "savings" && 
                                <img style={{opacity: 0.5}} className="list-items-icon" src="/savingsicon.png" />
                             }
                             {transaction.category == "loan" && 
                                <img style={{opacity: 0.5}} className="list-items-icon" src="/loan-icon.png" />
                             }
                             
                        </li>
                        {  transaction.category == "savings" || transaction.category == "reward"||  transaction.category == "cashback" 
                         || transaction.category == "card" || transaction.category == "fx" || transaction.category == "loan"? 
                            <li className="list-items">
                                {transaction.comment}
                            </li>
                             :
                             transaction.category == "bill" ?
                             <li className="list-items">
                                {transaction.billData.serviceCategrory.toUpperCase()}
                            </li>
                            :
                            <li className="list-items">
                                {this.isInComingTransfer(transaction.category) ? "INBOUND-TRANSFER" 
                                : transaction.receiverMetaData? "BANK TRANSFER" : transaction.category}
                            </li>
                        }
                        <li className="list-items">
                            {transaction.status}
                        </li>
                        <li className="list-items">
                            {moment(transaction.timeStamp).format('DD MMM YYYY')}
                        </li>
                        <li className="list-items">
                            <CurrencyFormat value={transaction.amount.toFixed(2)} displayType={'text'} thousandSeparator={true} 
                              prefix={transaction.currency} />
                              {transaction.discount > 0 &&
                                 " Discount " + transaction.discount
                              }
                        </li>
                        <li className="list-items">
                            <Link to={"/transactions-detail/" + transaction._id}>
                                <button className="trans-view-button">
                                    View
                                </button>
                            </Link>
                        </li>
                </ul>
            )
        }) 

        return (
            this.state.loading ? 
              <div style={{position: 'relative', marginLeft: '55%', marginTop: '15%'}}>
                  <Spinner color="#47c494" size={30}/>   
              </div> 
              :
            <div className="main-content-wrapper">
                <div className="content-title-wrap">
                    <p style={{fontSize:12, color:'#999', paddingLeft:10,}}>Transactions</p>
                </div>
                   
                <div className="row-title" >
                    <p style={{fontWeight:500}}>All Tx</p>
                    <CustomSelect 
                           isOpen={this.state.isOpen} 
                           onClick={this.isOpenFunc}  
                           selectedData={this.state.selectedData}
                           onSelect={this.onSelectFunc}
                           data={options}
                        />
                    <div className="search-input-wrapper">
                        
                        <div className="show-all-wrap">
                            <p>Show all</p>
                            <span onClick={this.showAllTransactionsFunc}
                            style={{backgroundColor: this.state.showAllTransactions ? '#6fcb7d' : 'transparent'}}></span>
                        </div>
                        <div className="show-all-wrap">
                            <p className="awaitingTransactionsCount">{this.state.awaitingCount}</p>
                            <p>Awaiting Tx</p>
                            <span onClick={this.showAwaitingTransactionsFunc}
                            style={{backgroundColor: this.state.showAwaitingTransactions ? '#6fcb7d' : 'transparent'}}></span>
                        </div>
                    <input type="text" name="queryString" value={this.state.queryString} placeholder="search" onChange={this.handleChange} />
                    <img src="/search-icon.png" onClick={this.findUserTransactions} />
                    </div>
                </div>

                <div className="list-container" >

                    <ul className="list-contents-title">
                        <li className="list-items-title">
                            Icon
                        </li>
                        <li className="list-items-title">
                             Type
                        </li>
                        <li className="list-items-title">
                            Status
                        </li>
                        <li className="list-items-title">
                            Time
                        </li>
                        <li className="list-items-title">
                            Amount
                        </li>
                        <li className="list-items-title">
                            View Details
                        </li>
                    </ul>
                     
                     {this.state.transactionsLoading ?
                       <div style={{position: 'relative', marginLeft: '55%', marginTop: '15%'}}>
                         <Spinner color="#47c494" size={20}/>   
                        </div> 
                        :
                      transactionsList
                     }
                    
                </div>

            </div>
               
        )
    }

}
